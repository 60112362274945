import {
  Box,
  Divider,
  FormControlLabel,
  MenuItem,
  Stack,
  Switch,
  Typography,
  styled,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { IconButtonAnimate } from '@subflow-frontend/components/animate';
import MyMerchantAccountAvatar from '@subflow-frontend/components/avatars/MyMerchantAccountAvatar';
import MenuPopover from '@subflow-frontend/components/menu-popover';
import { useDarkMode } from '@subflow-frontend/hooks/useDarkMode';
import { useSessionAuth } from '@subflow-frontend/hooks/useSessionAuth';
import NextLink from 'next/link';
import { useState } from 'react';
import { useCanAccessFeatureFlags } from '@subflow-frontend/hooks/featureFlag/useCanAccessFeatureFlags';
import CurrentOrgAvatar from '@subflow-frontend/components/avatars/CurrentOrgAvatar';
import { useSubscriberPaths } from '@subflow-frontend/routes/paths';
import { useMerchant, SUBSCRIBER_USER_TYPE } from '@subflow/data-access';
import OrgListPopoverLayout from '@subflow-frontend/layouts/shared/OrgListPopoverLayout';
import { formatOrgRole } from '@subflow-frontend/utils/formatRole';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff'
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

export const AccountMenu = ({ handleClose }) => {
  const { mode, toggleDarkMode } = useDarkMode();
  const canAccessFeatureFlags = useCanAccessFeatureFlags();
  const { data: merchant } = useMerchant();
  const { session } = useSessionAuth();
  const { subscriberPaths } = useSubscriberPaths();
  const isSubscriber = session.userType.includes(SUBSCRIBER_USER_TYPE);

  return (
    <OrgListPopoverLayout>
      <Stack>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <CurrentOrgAvatar />
            <Box sx={{ maxWidth: 160 }}>
              <Typography variant="subtitle2" noWrap>
                {merchant?.businessName}
              </Typography>
              <Typography
                variant="caption"
                sx={{ color: 'text.secondary' }}
                noWrap
              >
                {session?.user?.name}
              </Typography>
            </Box>
          </Stack>
        </Box>

        <Stack sx={{ p: 1 }} spacing={0} gap={0}>
          <NextLink href={'/org/dashboard'} passHref>
            <MenuItem onClick={handleClose}>Dashboard</MenuItem>
          </NextLink>

          <NextLink href={'/org/settings'} passHref>
            <MenuItem onClick={handleClose}>Settings</MenuItem>
          </NextLink>

          <NextLink href={'/org/settings/billing'} passHref>
            <MenuItem onClick={handleClose}>Billing</MenuItem>
          </NextLink>

          <NextLink href={'/org/settings/team'} passHref>
            <MenuItem onClick={handleClose}>Team Members</MenuItem>
          </NextLink>

          {isSubscriber && (
            <NextLink href={subscriberPaths.dashboard} passHref>
              <MenuItem onClick={handleClose}>View as Member</MenuItem>
            </NextLink>
          )}
        </Stack>

        <Divider>
          <Typography variant="caption">Your Account</Typography>
        </Divider>

        <Box sx={{ mb: 1.5, px: 2.5 }}>
          <Stack direction="row" alignItems="center" spacing={1} mt={2}>
            <MyMerchantAccountAvatar />

            <Box sx={{ maxWidth: 160 }}>
              <Typography variant="subtitle2" noWrap>
                {session?.user?.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary' }}
                noWrap
              >
                {session?.user?.email}
              </Typography>
            </Box>
          </Stack>
        </Box>

        <Stack sx={{ p: 1 }}>
          <NextLink href={'/account/dashboard'} passHref>
            <MenuItem onClick={handleClose}>Account Dashboard</MenuItem>
          </NextLink>

          <NextLink href={'/account/settings'} passHref>
            <MenuItem onClick={handleClose}>My Settings</MenuItem>
          </NextLink>

          <NextLink href={'/account/settings/devices'} passHref>
            <MenuItem onClick={handleClose}>Device Activity</MenuItem>
          </NextLink>

          <NextLink href={'/account/settings/security'} passHref>
            <MenuItem onClick={handleClose}>Security Settings</MenuItem>
          </NextLink>
          {canAccessFeatureFlags && (
            <NextLink href={'/account/settings/feature-flags'} passHref>
              <MenuItem onClick={handleClose}>Feature Flags</MenuItem>
            </NextLink>
          )}
          <NextLink href={'/auth/logout'} passHref>
            <MenuItem
              data-testid="account-popover-log-out-button"
              onClick={handleClose}
            >
              Logout
            </MenuItem>
          </NextLink>
        </Stack>

        <Divider />

        <MenuItem>
          <FormControlLabel
            onChange={toggleDarkMode}
            control={
              <MaterialUISwitch sx={{ m: 1 }} checked={mode === 'dark'} />
            }
            label={` Switch to ${mode === 'light' ? 'dark' : 'light'} mode`}
          />
        </MenuItem>
      </Stack>
    </OrgListPopoverLayout>
  );
};

export default function AccountPopover() {
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyMerchantAccountAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        {/*<AccountPopoverLayout>*/}
        <AccountMenu handleClose={handleClose} />
        {/*</AccountPopoverLayout>*/}
      </MenuPopover>
    </>
  );
}
