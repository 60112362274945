import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { PATH_PAGE } from '@subflow-frontend/routes/paths';
import {
  useFeatureFlag,
  useFeatureFlagsLoaded,
} from '@subflow-frontend/redux/slices/featureFlag/selectors';

export const useFeatureFlagWithRedirect = (
  feature: string,
  withRedirectTo404?: boolean
) => {
  const featureFlag = useFeatureFlag(feature);
  const featureFlagsLoaded = useFeatureFlagsLoaded();
  const { push } = useRouter();
  useEffect(() => {
    if (withRedirectTo404 && !featureFlag.enabled && featureFlagsLoaded) {
      push(PATH_PAGE.page404);
    }
  }, [featureFlag.enabled, featureFlagsLoaded, push, withRedirectTo404]);

  return featureFlag;
};
