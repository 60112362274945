import { useSessionAuth } from '@subflow-frontend/hooks/useSessionAuth';

const env = process.env.NODE_ENV;

export const useCanAccessFeatureFlags = () => {
  const { session } = useSessionAuth();
  return (
    session?.user?.email?.endsWith('@subflow.com') ||
    session?.user?.email?.endsWith('@getsubflow.com') ||
    env == 'development'
  );
};
