// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import useResponsive from '@subflow-frontend/hooks/useResponsive';
import Logo from '@subflow-frontend/components/Logo';
import { Button, Typography } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  image?: string;
  children: React.ReactNode;
};

export default function AuthLayout({ children, image }: Props) {
  const mdUp = useResponsive('up', 'md');

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 756,
        px: { xs: 2, md: 8 },
        py: { xs: 10, md: 0 },
        justifyContent: {
          xs: 'flex-start',
          md: 'center',
        },
      }}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 480,
          mx: 'auto',
          justifyContent: 'center',

          py: { xs: 5, md: 0 },
          px: { xs: 3, md: 0 },
          boxShadow: { md: 'none' },
          overflow: { md: 'unset' },
          bgcolor: { md: 'background.default' },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          sx={{
            mt: { xs: 2, md: 8 },
            mb: { xs: 10, md: 8 },
          }}
        >
          <Typography variant="caption">
            <Button href="https://www.subflow.com">
              <Logo disabledLink />
              Subflow
            </Button>
          </Typography>
        </Box>
        {children}
      </Card>
    </Stack>
  );

  const renderSection = (
    <Stack flexGrow={1} sx={{ position: 'relative' }}>
      <Box
        component="img"
        alt="auth"
        src={image || '/assets/login2.png'}
        sx={{
          top: 16,
          left: 16,
          objectFit: 'contain',
          objectPosition: 'center',
          position: 'absolute',
          width: 'calc(100% - 32px)',
          height: 'calc(100% - 32px)',
        }}
      />
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        maxWidth: 1440,
        mx: 'auto',
        minHeight: '100vh',
        position: 'relative',
      }}
    >
      {mdUp && renderSection}
      {renderContent}
    </Stack>
  );
}
