import { ReactNode, useState, useEffect } from 'react';
import { useSessionAuth } from '@subflow-frontend/hooks/useSessionAuth';
import { useRouter } from 'next/router';
import { MERCHANT_USER_TYPE } from 'libs/data-access/src/utils/constants';

type MerchantGuardProp = {
  children: ReactNode | string;
};

export const MerchantGuard = ({ children }: MerchantGuardProp) => {
  const [accessGranted, setAccessGranted] = useState(false);
  const { session } = useSessionAuth();
  const { push } = useRouter();

  useEffect(() => {
    const isMerchant = session.userType.includes(MERCHANT_USER_TYPE);
    if (isMerchant) {
      setAccessGranted(true);
    } else {
      push('/');
    }
  }, [push, session]);

  if (!accessGranted) {
    return null;
  }

  return <>{children}</>;
};
