// @ts-nocheck
import { Box, Button, Divider, Typography } from '@mui/material';
import Label from '@subflow-frontend/components/Label';
import { fNumber } from '@subflow-frontend/utils/formatNumber';
import { useMerchant } from '@subflow/data-access';
import { useState } from 'react';
import MenuPopover from '../../../components/menu-popover';

// ----------------------------------------------------------------------

export default function CreditsPopover({ isCollapse = false }) {
  const { isLoading, error, data } = useMerchant();
  const merchant = data;

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleClose = () => {
    setOpen(null);
  };

  const creditsRemaining = merchant?.creditsRemaining ?? 0;
  const creditsPurchased = merchant?.creditsPurchased ?? 0;
  const creditsPercentRemaining = (creditsRemaining / creditsPurchased) * 100;

  const getCreditsLabel = (percent: number) => {
    if (percent <= 5) {
      return {
        color: 'error',
      };
    } else if (percent <= 10) {
      return {
        color: 'warning',
      };
    } else {
      return {
        variant: 'outlined',
      };
    }
  };

  if (isCollapse) {
    return null;
  }

  if (error) {
    return (
      <Label>
        <Typography variant="caption" fontWeight={'bold'} color={'red'}>
          {/* @ts-ignore */}
          Error loading credits. ({error.message})
        </Typography>
      </Label>
    );
  }

  if (isLoading) {
    return (
      <Label>
        <Typography variant="caption" fontWeight={'bold'}>
          Loading Credits...
        </Typography>
      </Label>
    );
  }

  if (!merchant) {
    return null;
  }

  return (
    <>
      <Label {...getCreditsLabel(creditsPercentRemaining)}>
        <Typography variant="caption" fontWeight={'bold'} pr={1}>
          {fNumber(creditsRemaining)}
        </Typography>{' '}
        /{' '}
        <Typography variant="caption" pl={1}>
          {fNumber(creditsPurchased)}
        </Typography>
        <Typography variant="caption" pl={1}>
          Credits
        </Typography>
      </Label>

      <MenuPopover
        open={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            py: 2,
            px: 2.5,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Credit Balance</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {fNumber(creditsRemaining)} remaining credits.
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            Manage my plan
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
