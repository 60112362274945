import { CircularProgress, Divider, Grid, Stack } from '@mui/material';
import OrgAvatar from '@subflow-frontend/components/avatars/OrgAvatar';
import { useResponsive } from '@subflow-frontend/hooks';
import { useOrgs } from '@subflow-frontend/hooks/useOrgs';
import { useSessionAuth } from '@subflow-frontend/hooks/useSessionAuth';
import { preloadAssets } from '@subflow-frontend/utils/preloadAssets';
import {
  getOrganizationId,
  setOrganizationId,
  useGetPublicDataForMerchants,
} from '@subflow/data-access';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useMemo } from 'react';

export interface IOrgListPopoverLayoutProps {
  children: ReactNode;
}
const OrgListPopoverLayout = ({ children }) => {
  const router = useRouter();
  const { session } = useSessionAuth();

  const isDesktop = useResponsive('up', 'lg');

  const orgs = useOrgs();
  const { data, isLoading } = useGetPublicDataForMerchants({
    merchantIds: orgs.map((org) => org.id),
  });

  const onSelectOrg = (orgId) => {
    if (
      session.organizations &&
      session.organizations[orgId] &&
      !session.organizations[orgId].roles.length
    ) {
      return;
    }
    setOrganizationId(orgId);
    router.reload();
  };

  const currentOrgId = getOrganizationId();

  const merchants = useMemo(() => data?.results ?? [], [data]);

  useEffect(() => {
    preloadAssets(
      merchants.map((merchant) => merchant.logo).filter((image) => !!image)
    );
  }, [merchants]);

  return (
    <Grid container>
      <Grid item xs={12} sm={3} columnSpacing={0}>
        <Stack
          spacing={2}
          padding={2}
          sx={{
            borderStyle: 'solid',
            borderColor: 'rgba(145, 158, 171, 0.24)',
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderBottomWidth: { xs: 1, sm: 0 },
            borderRightWidth: { xs: 0, sm: 1 },
            alignItems: 'center',
          }}
          height={'100%'}
          direction={{ xs: 'row', sm: 'column' }}
        >
          {isLoading && <CircularProgress />}
          {!isLoading &&
            merchants.map((merchant) => {
              const disabled =
                session.organizations &&
                session.organizations[merchant.id] &&
                !session.organizations[merchant.id].roles.length;

              const styles = {
                outline:
                  merchant.id === currentOrgId ? '2px solid #2065D1' : '',
                border: merchant.id === currentOrgId ? '3px solid white' : '',
                cursor: disabled ? 'default' : 'pointer',
                transition: 'all 0.2s ease-in-out',
                opacity: disabled ? 0.1 : 0.8,
                '&:hover': {
                  boxShadow: disabled ? '' : '0 0 0 1px #2065D1',
                  opacity: disabled ? 0.1 : 1,
                  transform: disabled ? 'scale(1)' : 'scale(1.1)',
                },
              };

              return (
                <OrgAvatar
                  key={merchant.id}
                  onClick={() => {
                    onSelectOrg(merchant.id);
                  }}
                  sx={styles}
                  merchant={{
                    businessName: merchant.businessName,
                    logo: merchant.logo,
                  }}
                />
              );
            })}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={9}>
        {children}
      </Grid>
    </Grid>
  );
};

export default OrgListPopoverLayout;
