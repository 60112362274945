import { Box, Button, Typography } from '@mui/material';
import CurrentOrgAvatar from '@subflow-frontend/components/avatars/CurrentOrgAvatar';
import MenuPopover from '@subflow-frontend/components/menu-popover';
import { useResponsive } from '@subflow-frontend/hooks';
import { useSessionAuth } from '@subflow-frontend/hooks/useSessionAuth';
import { AccountMenu } from '@subflow-frontend/layouts/account/header/AccountPopover';
import { formatOrgRole } from '@subflow-frontend/utils/formatRole';
import { useMerchant } from '@subflow/data-access';
import { useState } from 'react';

// ----------------------------------------------------------------------

export default function AccountPopover({ isCollapse = false }) {
  const [openAccountMenu, setOpenAccountMenu] = useState<HTMLElement | null>(
    null
  );

  const { session } = useSessionAuth();
  const { data: merchant } = useMerchant();

  const isDesktop = useResponsive('up', 'lg');

  const handleOpenAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenAccountMenu(event.currentTarget);
  };

  const handleCloseAccountMenu = () => {
    setOpenAccountMenu(null);
  };

  return (
    <>
      <Box>
        <>
          <Box>
            <Button
              onClick={handleOpenAccountMenu}
              data-testid="account-popover"
              sx={{
                textAlign: 'left',
                textTransform: 'none',
                justifyContent: 'flex-start',
                width: 1,
              }}
            >
              <CurrentOrgAvatar />
              {!isCollapse && (
                <Box ml={1}>
                  <Typography variant="subtitle2" noWrap>
                    {merchant?.businessName}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ color: 'text.secondary' }}
                    noWrap
                  >
                    {session.user.name}
                  </Typography>
                </Box>
              )}
            </Button>
          </Box>
        </>
      </Box>

      <MenuPopover
        arrow={isDesktop ? 'left-top' : 'top-center'}
        open={openAccountMenu}
        onClose={handleCloseAccountMenu}
        sx={{
          p: 0,

          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
            minHeight: 1,
          },
        }}
      >
        <AccountMenu handleClose={handleCloseAccountMenu} />
      </MenuPopover>
    </>
  );
}
