import { useSessionAuth } from '@subflow-frontend/hooks/useSessionAuth';
import { ReactNode } from 'react';
import LoadingScreen from '../components/LoadingScreen';
import Login from '../pages/auth/login';
// import { useSnackbar } from 'notistack';
// import useWS from '../hooks/ws/hooks/useWS';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const { isAuthenticated, loading } = useSessionAuth();
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const { wsClient } = useWS();

  // useEffect(() => {
  //   if (isAuthenticated && expires && wsClient?.connected) {
  //     const currentTime = Date.now();
  //     const tenMinutesInMilliseconds = 1000 * 60 * 10;
  //     const sessionWarningTimeInSeconds =
  //       expires * 1000 - currentTime - tenMinutesInMilliseconds;

  //     const sessionTimer = setTimeout(() => {
  //       wsClient?.disconnect();
  //       //temporarily just log-out
  //       signOut();

  //       /*
  //            enqueueSnackbar('Your session is about to expire.', {
  //            anchorOrigin: {
  //            vertical: 'top',
  //            horizontal: 'center',
  //            },
  //            variant: 'info',
  //            persist: true,
  //            action: (key) => (
  //            <RenewLoginAction
  //            sessionExpirationTime={jwt.exp}
  //            onDismiss={() => closeSnackbar(key)}
  //            />
  //            ),
  //            });
  //          */
  //     }, sessionWarningTimeInSeconds);

  //     return () => {
  //       clearTimeout(sessionTimer);
  //     };
  //   }
  // }, [
  //   isAuthenticated,
  //   user,
  //   enqueueSnackbar,
  //   closeSnackbar,
  //   wsClient,
  //   expires,
  // ]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Login />;
  }

  return <>{children}</>;
}
