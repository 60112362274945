import { Props as AvatarProps } from '../Avatar';
import { CustomAvatar } from '@subflow-frontend/components/avatars/CustomAvatar';
import { useAccountSettings } from '@subflow/data-access';

// ----------------------------------------------------------------------

export default function MyMerchantAccountAvatar({ ...other }: AvatarProps) {
  const { data: account } = useAccountSettings();

  return (
    <CustomAvatar
      profileName={account?.fullName}
      profileLoaded={!!account}
      profileImage={account?.avatar}
    />
  );
}
