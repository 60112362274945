import { Box, Drawer, Stack } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useMerchant } from '@subflow/data-access';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import NavSectionVertical from '../../../components/nav-section/vertical2/nav-section-vertical';
import NavSectionMini from '../../../components/nav-section/mini/nav-section-mini';
import { NAVBAR } from '../../../config';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import useResponsive from '../../../hooks/useResponsive';
import cssStyles from '../../../utils/cssStyles';
import { useOrgNavConfig } from './NavConfig';
import { useCanAccess } from '@subflow-frontend/guards/RoleBasedGuard';
import { InviteMemberRequestDtoRoleEnum } from '@subflow/api-client';
import NavToggleButton from '@subflow-frontend/layouts/common/nav-toggle-button';
import AccountPopover from '../header/AccountPopover';
import CreditsPopover from '../header/CreditsPopover';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
  },
}));

// ----------------------------------------------------------------------

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function NavbarVertical({
  isOpenSidebar,
  onCloseSidebar,
}: Props) {
  const { data: merchant } = useMerchant();
  const { pathname } = useRouter();
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  const { isCollapse, collapseClick, collapseHover } = useCollapseDrawer();

  const accessGranted = useCanAccess([
    InviteMemberRequestDtoRoleEnum.Owner,
    InviteMemberRequestDtoRoleEnum.Admin,
    InviteMemberRequestDtoRoleEnum.Manager,
    InviteMemberRequestDtoRoleEnum.Member,
  ]);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const navConfig = useOrgNavConfig(merchant, !accessGranted);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Logo />
        </Stack>

        <AccountPopover isCollapse={isCollapse} />
        <CreditsPopover isCollapse={isCollapse} />
      </Stack>

      {isCollapse && <NavSectionMini data={navConfig} />}
      {!isCollapse && <NavSectionVertical data={navConfig} />}

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      <NavToggleButton />

      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              bgcolor: 'background.default',
              borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
