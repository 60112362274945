import { useState, useEffect, ReactNode } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR, PAGE_CONTENT } from '../../config';
//

import NavbarVertical from './navbar/NavbarVertical';
import { useDispatch } from '../../redux/store';
import { getConversations } from '../../redux/slices/chat';
import { useSessionAuth } from '@subflow-frontend/hooks/useSessionAuth';
import { useAccountSettings } from '@subflow/data-access';
import { IconButtonAnimate } from '@subflow-frontend/components/animate';
import Iconify from '@subflow-frontend/components/iconify';
import { useResponsive } from '@subflow-frontend/hooks';

// ----------------------------------------------------------------------

type MainStyleProps = {
  collapseClick: boolean;
  paddingBottom?: number;
  disableMarginRight?: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) =>
    prop !== 'collapseClick' &&
    prop !== 'paddingBottom' &&
    prop != 'disableMarginRight',
})<MainStyleProps>(
  ({ collapseClick, paddingBottom, disableMarginRight, theme }) => ({
    flexGrow: 1,
    paddingTop: 24,
    paddingBottom: HEADER.MOBILE_HEIGHT + 24,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 16,
      paddingRight: disableMarginRight ? 0 : PAGE_CONTENT.MARGIN_RIGHT,
      paddingTop: +24,
      paddingBottom:
        paddingBottom || paddingBottom === 0
          ? paddingBottom
          : HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
      width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
      ...(collapseClick && {
        marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
      }),
    },
  })
);

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  disableMarginRight?: boolean;
  paddingBottom?: number;
};

export default function DashboardLayout({ children, ...rest }: Props) {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSessionAuth();
  // prefetch account settings
  useAccountSettings(!isAuthenticated);
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getConversations({}));
    }
  }, [dispatch, isAuthenticated]);

  const { collapseClick, isCollapse } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const isDesktop = useResponsive('up', 'lg');

  return (
    <Box
      sx={{
        display: {
          lg: 'flex',
        },
        minHeight: { lg: 1 },
      }}
    >
      {!isDesktop && (
        <Box
          position={'sticky'}
          top={1}
          zIndex={999}
          width={1}
          bgcolor={'white'}
        >
          <IconButtonAnimate
            onClick={() => setOpen(true)}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        </Box>
      )}

      <NavbarVertical
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />

      <MainStyle collapseClick={collapseClick} {...rest}>
        {children}
      </MainStyle>
    </Box>
  );
}
