import { useSessionAuth } from '@subflow-frontend/hooks/useSessionAuth';
import { useMemo } from 'react';

export const useOrgs = () => {
  const { session } = useSessionAuth();

  const orgs = useMemo(() => {
    return Object.entries(session?.organizations ?? []).map(
      ([orgId, orgDetails]) => {
        return {
          id: orgId,
          name: orgDetails.name,
        };
      }
    );
  }, [session?.organizations]);
  return orgs;
};
