import { ReactNode } from 'react';
// @mui
import { Stack } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function LoginLayout({ children }: Props) {
  return (
    <Stack sx={{ minHeight: 1, height: '100vh' }}>
      {children}
    </Stack>
  );
}
