// icons
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ChatIconOutlined from '@mui/icons-material/ChatOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FeedIcon from '@mui/icons-material/Feed';
import GroupsIcon from '@mui/icons-material/Groups';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import SmsIconOutlined from '@mui/icons-material/SmsOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import Label from '@subflow-frontend/components/Label';
import { PATH_DASHBOARD } from '@subflow-frontend/routes/paths';
import { NavListProps } from '../../../components/nav-section/type';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import { useCoursesFeatureFlag } from '@subflow-frontend/hooks/featureFlag/features/useCoursesFeatureFlag';

// ----------------------------------------------------------------------

export const TextMessagesTitle = 'Text Messages';
const onboarding = {
  title: 'Onboarding',
  path: '/org/onboarding',
  icon: <LibraryAddCheckOutlinedIcon fontSize="small" />,
};

export const useOrgNavConfig = (merchant: any, hideSupportTicket = false) => {
  const showOnboarding = merchant?.onboardingHistory?.skippedPaymentAccountSetup
    ? false
    : merchant?.onboardingHistory?.missingSubscription;
  const { enabled: coursesPageEnabled } = useCoursesFeatureFlag();
  return [
    // Dashboard
    {
      active: true,
      subheader: `Organization`,
      items: [
        {
          title: 'Dashboard',
          path: '/org/dashboard',
          icon: <DashboardCustomizeOutlinedIcon fontSize="small" />,
        },
        ...(showOnboarding ? [onboarding] : []),
      ],
    },
    // ----------------------------------------------------------------------
    // COMMUNICATION

    {
      active: true,
      subheader: 'COMMUNICATION',
      items: [
        {
          title: TextMessagesTitle,
          path: PATH_DASHBOARD.messaging.conversation.root,
          icon: <SmsIconOutlined fontSize="small" />,
          data: 'conversations',
        },

        {
          title: 'Email',
          path: PATH_DASHBOARD.communication.newsletter.root,
          icon: <EmailOutlinedIcon fontSize="small" />,
        },

        {
          title: 'Sequences',
          path: PATH_DASHBOARD.communication.sequences.root,
          icon: <TimelineOutlinedIcon fontSize="small" />,
        },
        {
          title: 'Meetings',
          path: PATH_DASHBOARD.communication.meetings.root,
          icon: <PhotoCameraFrontIcon fontSize="small" />,
          info: <Label color="info">BETA</Label>,
        },
      ],
    },

    {
      active: true,
      subheader: 'COMMUNITY',
      items: [
        {
          title: 'Feed',
          path: PATH_DASHBOARD.communication.feed.root,
          icon: <FeedIcon fontSize="small" />,
          data: 'Feed',
        },

        {
          title: 'Chat',
          path: PATH_DASHBOARD.messaging.chat.root,
          icon: <ChatIconOutlined fontSize="small" />,
          data: 'conversations',
          info: <Label color="info">BETA</Label>,
        },
      ],
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      active: true,
      subheader: 'Management',
      items: [
        {
          title: 'Subscriptions',
          id: 'navbar-subscriptions-button',
          path: '/org/store/subscriptions',
          icon: <StoreOutlinedIcon fontSize="small" />,
        },
        {
          title: 'Contacts',
          path: '/org/store/customers',
          icon: <PersonAddAltOutlinedIcon fontSize="small" />,
        },
        {
          title: 'Segments',
          path: '/org/messaging/segments',
          icon: <GroupsIcon fontSize="small" />,
        },
        {
          title: 'Calendar',
          path: '/org/calendar',
          icon: <CalendarMonthOutlinedIcon fontSize="small" />,
        },
      ],
    },
    {
      active: true,
      subheader: 'Content',
      items: [
        ...(coursesPageEnabled
          ? [
              {
                title: 'Courses',
                path: '/content/courses',
                icon: <ViewQuiltIcon fontSize="small" />,
                info: <Label color="info">BETA</Label>,
              },
            ]
          : []),
        {
          title: 'Pages',
          path: '/content/pages',
          icon: <ViewQuiltIcon fontSize="small" />,
        },
        {
          title: 'Library',
          path: '/content/library',
          icon: <CollectionsOutlinedIcon fontSize="small" />,
        },
      ],
    },

    {
      active: true,
      subheader: 'Support',
      items: [
        hideSupportTicket
          ? null
          : {
              title: 'Support Ticket',
              path: '/org/support/ticket',
              icon: <ContactSupportOutlinedIcon fontSize="small" />,
            },
        // {
        //   title: 'Resources',
        //   path: '/org/resources',
        //   icon: <TipsAndUpdatesOutlinedIcon fontSize="small" />,
        //   children: [
        //     { title: 'Welcome', path: '/org/resources/welcome' },
        //     {
        //       title: 'Create Subscription',
        //       path: '/org/resources/create-subscription',
        //     },
        //     {
        //       title: 'Send Messages',
        //       path: '/org/resources/send-messages',
        //     },
        //   ],
        // },
        {
          title: 'Contact Us',
          path: 'https://www.subflow.com/contact-us',
          icon: <AttachEmailOutlinedIcon fontSize="small" />,
        },
      ].filter(Boolean) as NavListProps[],
    },
  ];
};
