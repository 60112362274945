import { useSelector } from '@subflow-frontend/redux/store';

export const useFeatureFlag = (feature: string) => {
  const featureFlag = useSelector(
    (state) => state.featureFlag.features.byId[feature]
  );
  return {
    feature,
    enabled: featureFlag?.enabled || false,
    variant: featureFlag?.variant,
  };
};

export const useFeatureFlagsLoaded = () => {
  return useSelector((state) => state.featureFlag.features.loaded);
};
