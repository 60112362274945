import { ReactNode, useEffect } from 'react';
// guards
import AuthGuard from '../guards/AuthGuard';
// components
import DashboardLayout from './dashboard';
import NonAuthenticatedSubscriberLayout from 'apps/frontend/layouts/NonAuthenticatedSubscriberLayout';
import OnboardingLayout from './onboarding';
import LoginLayout from './login/index';
import { WSProvider } from '@subflow-frontend/hooks/ws';
import { useDispatch } from '@subflow-frontend/redux/store';
import { initializeFeatureFlags } from '@subflow-frontend/redux/slices/featureFlag';
import { Box, Button, Typography } from '@mui/material';
import Logo from '@subflow-frontend/components/Logo';
import { useSessionAuth } from '@subflow-frontend/hooks/useSessionAuth';
import { datadogRum } from '@datadog/browser-rum';
import { getOrganizationId } from '@subflow/data-access';
import { useIdleTimer } from 'react-idle-timer';
import AuthLayout from './AuthLayout';
import { MerchantGuard } from '@subflow-frontend/guards/MerchantGuard';
import { datadogLogs } from '@datadog/browser-logs';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  logo?: string;
  variant?: 'dashboard' | 'logoOnly' | 'login' | 'auth';
  paddingBottom?: number;
  disableMarginRight?: boolean;
};

export default function Layout({
  variant = 'dashboard',
  children,
  logo,
  ...rest
}: Props) {
  const dispatch = useDispatch();
  const { loading, isAuthenticated, user, update } = useSessionAuth();

  const SESSION_IDLE_TIME = 1000 * 60 * 14; // 14 mins

  const onUserIdle = () => {
    console.log('IDLE');
  };

  const onUserActive = () => {
    console.log('ACTIVE');
  };

  const { isIdle } = useIdleTimer({
    onIdle: onUserIdle,
    onActive: onUserActive,
    timeout: SESSION_IDLE_TIME, //milliseconds
    throttle: 500,
  });

  useEffect(() => {
    if (!loading && isAuthenticated && user) {
      // Set the user context for RUM
      datadogRum.setUser({
        id: user.id,
        name: user.name,
        email: user.email,
        organization: getOrganizationId(),
        // plan: 'premium', // Todo: Add current payment plan
      });
      datadogLogs.setUser({
        id: user.id,
        name: user.name,
        email: user.email,
        organization: getOrganizationId(),
      });
    }
  }, [isAuthenticated, loading, user]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isIdle) {
        console.log('refreshing idle app token');
        update(); // extend client session
      }
    }, SESSION_IDLE_TIME);
    return () => clearInterval(interval);
  }, [update]);

  useEffect(() => {
    dispatch(initializeFeatureFlags());
  }, []);

  if (variant === 'auth') {
    return <AuthLayout> {children} </AuthLayout>;
  }

  if (variant === 'login') {
    return <LoginLayout>{children}</LoginLayout>;
  }

  return (
    <WSProvider>
      <AuthGuard>
        <MerchantGuard>
          <DashboardLayout {...rest}> {children} </DashboardLayout>
        </MerchantGuard>
      </AuthGuard>
    </WSProvider>
  );
}
