import { Box, Container, Typography } from '@mui/material';
import Page from '@subflow-frontend/components/Page';
import { getServerSession } from 'next-auth';
import { signIn } from 'next-auth/react';
import { useEffect } from 'react';
import Layout from '../../layouts';
import { authOptions } from '../api/auth/[...nextauth]';
import { useRouter } from 'next/router';

LoginPage.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout variant="logoOnly">{page}</Layout>;
};

export default function LoginPage() {
  const { asPath, query, push } = useRouter();
  useEffect(() => {
    if (!query.error) {
      signIn(
        'keycloak',
        query.callbackUrl && { callbackUrl: query.callbackUrl as string }
      );
    } else {
      push(`/auth/error?error=${query.error}`);
    }
  }, [query, push]);

  return (
    <Page title="Login" sx={{ minHeight: '100vh' }}>
      <Container>
        <Box
          sx={{
            display: 'flex',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Checking Session...</Typography>
        </Box>
      </Container>
    </Page>
  );
}

export async function getServerSideProps({ req, res }) {
  const session = await getServerSession(req, res, authOptions);

  if (session) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
}
