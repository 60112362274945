import { Box } from '@mui/material';
import { useMerchant } from '@subflow/data-access';
import OrgAvatar from '@subflow-frontend/components/avatars/OrgAvatar';

export default function CurrentOrgAvatar() {
  const { data: merchant } = useMerchant();
  return (
    <Box>
      <OrgAvatar merchant={merchant} />
    </Box>
  );
}
